.pull-left {
    float: left;
}

.form-control {
    width: 100%;
}

.hide {
    display: none;
}

.clear {
    clear: both ;
}


.fg-label sup a {
    color:blue;
    cursor: pointer;
}

.form-floating-btn {
    float: right;
    position: relative;
    margin-top: -34px;
    margin-right: 8px;
}


.form-floating-btn button {
    margin-left: 5px;
    border: 0px !important;
    font-size:20px;
    background-color:transparent;
    color:#444444;
}

.form-floating-btn button:hover {
    color:#449944;
    background-color:transparent;
}




.form-field-required_empty {
    border-color: #e1665d !important;
}




@media (max-width: 700px) {
    .GeneratedForm .form-group {
        width: 100% !important;
    }


}



